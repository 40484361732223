import React, { useEffect } from 'react'


import Layout from '../components/layout'
import { Contact } from '../components/contact'
import { SmallTitlePage } from '../components/common/smallTitlePage'
import { useStaticQuery, graphql } from 'gatsby'
import Seo from '../components/seo'



const LodzKontakt = () => {

  const data = useStaticQuery(graphql`
  {
      file(relativePath: {eq: "bodymedgroup-bydgoszcz.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            
          )
        }
      }
    }
`)

return (
  <Layout lodz>
    <Seo
      title="Lodz kontakt"
      description="Kontakt w BodyMed Group Lodz."
      url="https://bodymedgroup.pl/lodz-kontakt"
    />
    <SmallTitlePage
      title='Kontakt' 

    />
    <Contact lodz/>
  </Layout>
)
}

export default LodzKontakt
